.heroBody {
  background: #cfebff5c;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10% 5%;
  background-image: url('../../Assets/svg/mainCurveImage.svg');
  background-repeat: no-repeat;
  background-position: top -213px right 0%;
}

.heroSideBar {
  background-image: url("../../Assets//svg//heroImage.svg");
  background-repeat: no-repeat;
}

.heroText {
  color: var(--Body-Text, #596080);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 6%;
}

.aboutTittle {
  color: #171717;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  width: 55%;
}

.aboutSubheading {
  color: var(--Light-Black, #171717);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.aboutpara {
  color: var(--Body-Text, #596080);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-left: 6%;
}

.techHeading {
  color: var(--Light-Black, #171717);

  /* Heading Semibold/H2 */
  font-family: Montserrat;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 126.316% */
}

.themeBtn {
  height: 50px;
  width: 225px;
  border-radius: 8px;
  background: linear-gradient(
    90deg,
    #ffa438 -244.37%,
    #ffa438 -7.65%,
    #197cc1 -7.06%,
    #0078d1 50%
  );
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.themeBtn:active {
  transform: translateY(4px);
}

.filledBtn {
  height: 50px;
  width: 120px;
  border-radius: 8px;
  border: 1px solid rgba(23, 23, 23, 0.5);
  font-size: 16px;
  font-weight: 600;
  background: none;
}

.ourProcessBody {
  background-image: url("../../Assets//svg/our-process-bg.svg");
  background-size: cover;
  padding: 5% 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ourProcessText {
  width: 80%;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(97, 137, 198, 0.1);
  border-radius: 10px;
  margin: 0 10%;
  font-size: 22px;
  font-weight: 600;
  color: #171717;
}
.caseStudyImage{
  width: 600px !important;
  border-radius: 10px;
}

.webHide {
  display: block;
}
.mobileHide {
  display: none;
}

.clients {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 3%;
  align-items: center;
  flex-wrap: wrap;
}

.clients img {
  height: 40px;
  border-radius: 10px;
  object-fit: contain;
  padding: 10px;
}
.svagImage {
  width: 100%;
}
.aboutVideo {
  width: 45%;
}

.aboutService {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80%;
}

.feedbackCardParent {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.feedBackCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 25%;
  border-radius: 10px;
  padding: 4% 2%;
  background-color: #cfebff5c;
}

.caseStudyBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
}

.caseStudyContent {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: flex-start;
  width: 50%;
  gap: 30px;
}

.blogParent {
  display: flex;
  flex-direction: row;
  gap: 4%;
}

.contactFormBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5% 5% 5% 0;
  background: #cfebff5c;
  gap: 20px;
}

.infoCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 4%;
  background: white;
  height: 70%;
  margin: auto 0;
  border-radius: 0;
}

.inputForm {
  width: 100%;
  height: 40px;
  border: 1px solid white;
  padding: 5px 20px;
  border-radius: 10px;
}

.inputFormParent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selectForm {
  width: 48%;
  height: 50px;
  border: 1px solid white;
  padding: 5px 20px;
  border-radius: 10px;
  color: #0000008c;
}

.caseStudyScreen{
  display: flex;
  flex-direction: row;
  gap: 40px;
  border-radius: 20px;
}


@media screen and (max-width: 1300px) {
  .webHide {
    display: none;
  }

  .mobileHide {
    display: block;
  }

  .caseStudyScreen{
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-radius: 20px;
    padding: 10px;
  }

  .heroBody {
    background: #cfebff5c;
    display: flex;
    flex-direction: column;
    padding: 20% 5%;
    gap: 30px;
    background-image: url('../../Assets/svg/mainCurveImage.svg');
  }

  .caseStudyImage{
    width: 100% !important;
    border-radius: 10px;
  }
  

  .clients img {
    height: 20px;
    border-radius: 10px;
    object-fit: contain;
    padding: 10px;
  }

  .themeBtn {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(
      90deg,
      #ffa438 -244.37%,
      #ffa438 -7.65%,
      #197cc1 -7.06%,
      #0078d1 50%
    );
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-top: 30px;
  }

  .svagImage {
    display: none;
  }

  .aboutVideo {
    width: 100% !important;
  }

  .aboutTittle {
    color: #171717;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    width: 100%;
  }

  .aboutService {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 90%;
  }

  .aboutpara {
    color: var(--Body-Text, #596080);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-left: 10%;
  }

  .feedbackCardParent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .feedBackCard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    border-radius: 10px;
    padding: 4%;
  }

  .caseStudyBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5%;
  }

  .caseStudyContent {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: flex-start;
    width: 100%;
    gap: 30px;
  }

  .blogParent {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .contactFormBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5%;
    background: #cfebff5c;
  }

  .infoCard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 4%;
    background: white;
    height: 70%;
    margin: auto 0;
    border-radius: 10px;
  }

  .inputForm {
    width: 90%;
    height: 40px;
    border: 1px solid white;
    padding: 5px 20px;
    border-radius: 10px;
  }

  .inputFormParent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .selectForm {
    width: 100%;
    height: 50px;
    border: 1px solid white;
    padding: 5px 20px;
    border-radius: 10px;
    color: #0000008c;
  }
}

video::-webkit-media-controls {
  display: none;
}
