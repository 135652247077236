.blogCard {
  display: flex;
  flex-direction: column;
  width: 32%;
  border-radius: 10px;
  cursor: pointer;
}

.blogTittle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  margin-top: 10vh;
}

.blogCardParent{
  width: 90%;
}

@media screen and (max-width: 1300px) {
  .blogCard {
    display: flex;
    flex-direction: column;
    width: 98%;
    border-radius: 10px;
    cursor: pointer;
  }

  .blogTittle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    margin-top: 5vh;
    width: 100%;
  }

  .blogCardParent{
    width: 100%;
  }
}
