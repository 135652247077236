.contactFormImagebox {
  width: 31%;
}

.contactFormInputBox {
  width: 50%;
}

.contactFormParent {
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4%;
  border-radius: 20px;
  width: 70%;
  justify-content: space-between;
  margin: auto;
}

.contactFormMain {
  padding: 4% 10%;
}

.webHide {
  display: block;
}
.mobileHide {
  display: none;
}

@media screen and (max-width: 1300px) {
  .contactFormImagebox {
    width: 80%;
  }

  .contactFormInputBox {
    width: 100%;
  }

  .contactFormParent {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    justify-content: space-between;
    margin: auto;
  }

  .contactFormMain {
    padding: 0;
  }
  .webHide {
    display: none;
  }
  .mobileHide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
