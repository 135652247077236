.topbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  margin-top: 1vh;
}

.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 80%;
  margin: 5vh auto 4% auto;
}

.caseDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  gap: 15px;
}

.caseDetailsTittle {
}

.techImage {
}

.caseDetailsFirstSectionImage {
}

.caseDetailsFirstSection {
  display: flex;
  flex-direction: row;
  padding: 5%;
  align-items: center;
  width: 90%;
}

.caseDetailsFirstSectionBody {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  padding: 0 83px;
}

.caseDetailsFirstSectionText {
  width: 70%;
  font-size: 18px;
  font-weight: 400;
}

.caseDetailsBannerBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.caseDetailsBannerImage {
  width: 90%;
  border-radius: 10px;
}

.caseDetailsProblemBody {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 5%;
  gap: 10px;
}

.caseDetailsProblemArray {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 40%;
  padding: 5px;
}

.caseDetailsSolutionBody {
  display: flex;
  flex-direction: row;
  gap: 80px;
  width: 100%;
  align-items: center;
}

.caseDetailsTextBody {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 660px;
  margin-left: 4%;
}

.caseDetailsImpactBody {
  display: flex;
  flex-direction: row;
  gap: 80px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.webHide {
  display: block;
}
.mobileHide {
  display: none;
}

@media screen and (max-width: 1300px) {
  .topbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    margin-top: 5vh;
  }

  .cardBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 90%;
    margin: 3vh auto 4% auto;
  }

  .caseDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    gap: 15px;
    padding-top: 6vh;
  }

  .caseDetailsTittle {
    width: 100%;
    text-align: center;
  }

  .techImage {
    width: 40px;
  }

  .caseDetailsFirstSection {
    display: flex;
    flex-direction: column;
    padding: 5%;
    align-items: center;
    width: 90%;
  }

  .caseDetailsFirstSectionImage {
    width: 100%;
    margin-top: 2vh;
  }

  .caseDetailsFirstSectionBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    padding: 0px;
    align-items: center;
  }

  .caseDetailsFirstSectionText {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }

  .caseDetailsBannerBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }

  .caseDetailsBannerImage {
    width: 100%;
    border-radius: 0;
  }

  .caseDetailsProblemBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 5%;
  }

  .caseDetailsProblemArray {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    padding: 5px;
  }

  .caseDetailsSolutionBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
  }

  .caseDetailsTextBody {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 90%;
    /* margin-left: 4%; */
  }

  .caseDetailsImpactBody {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .webHide {
    display: none;
  }

  .mobileHide {
    display: block;
  }
}
