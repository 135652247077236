body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading {
  color: var(--Light-Black, #171717);
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  /* font-family: Montserrat; */
}

.themeColor1 {
  background: #cfebff5c;
}

@media screen and (max-width: 1300px) {
  .heading {
    color: var(--Light-Black, #171717);
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    /* font-family: Montserrat; */
  }
}
