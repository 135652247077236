.footerBody{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5% 5% 0 5%;
}
.boxStyle {
  display: flex;
  flex-direction: row;
  gap: 10%;
  margin-top: 30px;
}
.boxStyle div {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 5px;
  align-items: flex-start;
}

.boxStyle h3 {
  font-size: 16px;
  font-weight: 600;
  color: #171717;
}

.boxStyle span {
  font-size: 16px;
  font-weight: 400;
  color: #596080;
}

.copyRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.copyRight span {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  color: #596080;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #5960802e;
  margin-top: 30px;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .boxStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
  }

  .copyRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .copyRight span {
    margin: 5px 0;
    font-size: 16px;
    font-weight: 400;
    color: #596080;
  }
  .socials {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 30px 0;
  }
}
