.navbarBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2% 5%;
  align-items: center;
  background: #eef8ff;
}
.hoverEffect:hover {
  color: blue;
}
.navBarRoutes {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
}
.navBarRoutes a:hover {
  color: rgb(13, 13, 125);
}

.navBarRoutes button {
  height: 50px;
  width: 120px;
  border-radius: 8px;
  border: 1px solid rgba(23, 23, 23, 0.5);
  font-size: 16px;
  font-weight: 600;
  background: none;
}

.navBarRoutes button:hover {
  background-color: #0078d1;
  color: white;
}

.mobileHide {
  display: none;
}

.webHide {
  display: flex;
  justify-content: flex-end;
  width: 56%;
  gap: 20px;
  margin-right: 4%;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .mobileHide {
    display: block;
  }

  .webHide {
    display: none;
  }
}
